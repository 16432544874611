//import manager from '@/utils/manager'
import api from '@/utils/api'

export function accountInfo(params) {
    return api.get('wx/v1/gold/account', { params:params })
}

export function gameList(params) {
    return api.get('wx/v1/gold/game', { params:params })
}

export function serverList(params) {
    return api.get('wx/v1/gold/server', { params:params })
}

export function getServerList(params) {
    return api.get('wx/v1/gold/get_server', { params:params })
}

export function getRoleList(params) {
    return api.get('wx/v1/gold/get_role', { params:params })
}
export function getGameId(params) {
    return api.get('wx/v1/gold/get_game', { params:params })
}

export function roleList(params) {
    return api.get('wx/v1/gold/role', { params:params })
}
export function payStripe(params) {
    return api.post('stripe/pay', params)
}

export function payLianLian(params) {
    return api.post('lianLianPay/pay', params)
}

export function payThunderSnake(params) {
    return api.post('thundersnakePay/pay', params)
}

export function productsList(params) {
    return api.get('wx/v1/gold/products', { params:params })
}

export function createOrder(params) {
    return api.post('wx/v1/jzac', params)
}
export function stripeMoneyList(params) {
    return api.post('stripe/moneyList', params)
}

export function pay(params) {
    // return api.post('wx/v1/gold/pay', params)
    params.project_id = 'gold';
    params.type = 2;
    return api.post('wx/v1/official/common/wxpay', params)
}

export function commonCreateOrder(params) {
    return api.post('wx/v1/gold/common/jzac', params)
}

//支付包支付或paypel支付
export function payMix(params) {
    // return api.post('wx/v1/gold/pay/mix', params)
    return api.post('wx/v1/official_account/pay', params)
}

//公众号微信支付
export function payWxRedirect(orderNum) {
  // return api.post('wx/v1/gold/pay/wxRedirect', { order_num: orderNum })

  //    最新版公众号微信支付
  return api.post('wx/v1/official/common/wxpay', { order_num: orderNum, type: 1, project_id: 'gold' })
}

//获取打金传说的活动
export function discountActivity(params)
{
    return api.post('wx/v1/gold/discount/activity', params)
}

//  mycard台湾支付
export function payMycard(params) {
    return api.post('mycardPay/pay', params)
}

